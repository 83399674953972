<template>
  <div id="menu">
    <h1 style="font-weight: bold; color: white">Our Menu</h1>
    <h6 style="color: #CBA661; font-weight: bold; letter-spacing: 9px">Customer's favorites</h6>
    <div style="height: 20px"></div>
    <div v-if="loading" class="text-center">
      <b-spinner variant="secondary" label="Loading"></b-spinner>
    </div>
    <b-card-group deck>
      <mikepad v-if="loading"></mikepad>
      <b-card class="border-0" v-for="menu in menus" :key="menu.id" :title="menu.name" :img-src="menu.image" :img-alt="menu.name" img-top>
        <b-card-text>
          € {{ menu.price.toFixed(2) }}
        </b-card-text>
        <template v-slot:footer>
          <small class="text-muted">{{ menu.remark_en }}</small>
        </template>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menus: [],
      loading: true
    }
  },
  beforeCreate () {
    this.axios
      .get('https://batavia-backend.herokuapp.com/api/menu?nocache=' + new Date().getTime())
      .then(response => (this.menus = response.data))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    scrollTo(selector) {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  },
  metaInfo: {
    title: 'Menu',
    titleTemplate: '%s ← Batavia Amsterdam',
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Menu - Our customer\'s favorites'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.bataviamsterdam.com/menu'}
    ]
  }
}
</script>


<style scoped>
  @media only screen and (max-device-width: 568px) {
    #menu {
      margin-bottom: 2em;
    }
  }

  @media only screen and (min-device-width: 569px) and (max-device-width: 2000px) and (orientation: portrait) {
    #menu {
      margin-bottom: 17em;
    }
  }

  @media only screen and (min-device-width: 569px) and (max-device-width: 2000px) and (orientation: landscape) {
    #menu {
      margin-bottom: 1em;
    }
  }
</style>